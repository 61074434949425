<template>
  <CModal id="IssueTicket" class="ActionModel" :show.sync="Toggle" :centered="true" size="xl" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template>
      <CInput :label="'發送對象'" :value="Receiver" horizontal disabled />
      <CSelect horizontal :label="'折價券優惠'" :options="DiscountOfferOption" v-model="DiscountOffer" :value.sync="DiscountOffer" :placeholder="$t('Global.PleaseSelect')" @update:value="InputMessage"/>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          發送信件通知
        </CCol>
        <CCol sm="9">
          <CSwitch color="success" :checked.sync="NeedNotify"/>
        </CCol>
      </CRow>
      <template v-if="NeedNotify === true">
        <CSelect v-if="IsSendMessage === false" label="內容類型" horizontal :options="MessageTypeList" v-model="MessageType" :value.sync="MessageType" :placeholder="$t('Global.PleaseSelect')" />
        <CRow form class="form-group">
          <CCol tag="label" sm="3" class="col-form-label">
            自訂訊息內容
          </CCol>
          <CCol sm="9">
            <CSwitch color="success" :checked.sync="IsSendMessage"/>
          </CCol>
        </CRow>
        <template v-if="IsSendMessage === true">
          <CInput :label="'主旨'" v-model="Title" horizontal />
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
            </CCol>
            <CCol sm="9">
              <Editor api-key="x3zt0pfu7uw915eab118wco1xsrza3rhkysn57vzbfmivqxo" :init="TinyMCE" v-model="Content" />
            </CCol>
          </CRow>
        </template>
      </template>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Organization/Member.DistributeTicket') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="Issue()" color="success" :disabled="CurrentMemberList.length <= 0">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'
export default {
  name: "IssueTicket",
  props: ['Toggle', 'MemberList', 'ChooseUsers'],
  components: {
    Editor
  },
  data() {
    return {
      TinyMCE: TinyMCESetting,
      Submit: false,
      Loading: false,
      DiscountOfferOption: [],
      NeedNotify: true,
      IsSendMessage: false,
      MessageType: 'IssueTicket',
      MessageTypeList: [
        {
          label: '會員禮券發送通知',
          value: 'IssueTicket'
        },
        {
          label: '會員生日折價券發送通知',
          value: 'BirthdayIssueTicket'
        }
      ],
      Title: '',
      ContentTitle: '',
      Content: '',
      CurrentMemberList: [],
      DiscountOffer: ''
    }
  },
  computed: {
    Receiver () {
      if (this.CurrentMemberList.length === 1) {
        return `${this.CurrentMemberList[0].Name}(${this.CurrentMemberList[0].Email})`
      } else if (this.CurrentMemberList.length === this.MemberList.length) {
        return '發送給目前篩選條件下所有會員'
      } else {
        return '發送給所選' + this.CurrentMemberList.length + '位會員'
      }
    }
  },
  mounted() {
    if (this.ChooseUsers.length > 0) {
      this.CurrentMemberList = this.MemberList.filter(item => this.ChooseUsers.includes(item.Uid))
    } else {
      this.CurrentMemberList = this.MemberList
    }
    return Promise.all([
      this.GetOfferList()
    ])
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:ChooseUsers', [])
    },
    GetOfferList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/systemList2',
          Data: {
            Taxonomy: 'DiscountTicket',
            Status: 'Y'
          }
        }
      }).then(({data}) => {
        this.Loading = false
        this.DiscountOfferOption = data.Data.filter(item => {
          return item.Status === 'Y' && (this.$dayjs().unix() <= this.$dayjs(item.EndTime).unix())
        }).map(item => {
          item.label = `${item.Name} (${item.Slug})`
          item.value = item._id
          return item
        })
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    InputMessage() {
      const SelectOffer = this.DiscountOfferOption.find(item => item._id === this.DiscountOffer)
      this.Title = `【會員禮券】您的「${SelectOffer.Name}」專屬優惠`
      this.ContentTitle = `您的專屬禮券`
      this.Content = `<p>恭喜您獲得了「{:OFFER_NAME:}」專屬會員折價券，請於會員中心內查看。<br>優惠使用期限：{:OFFER_STARTTIME:}~{:OFFER_ENDTIME:}</p><p>{:OFFER_DESCRIPTION:}</p>`
    },
    Issue() {
      this.Loading = true
      const data = {
        Uid: this.ChooseUsers,
        Recipient: this.CurrentMemberList.map(item => {
          return {
            ...item,
            Email: item.Email,
            Name: item.Name
          }
        }),
        Offer: this.DiscountOfferOption.find(item => item._id === this.DiscountOffer),
        EnableNotify: this.NeedNotify
      }
      if (this.IsSendMessage === true) {
        data.Title = this.Title
        data.CustomContent = {
          Title: this.ContentTitle,
          Content: this.Content
        }
      } else {
        data.MessageType = this.MessageType
      }
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/offer/issue/byMember',
        method: 'post',
        data
      }).then(() => {
        this.Loading = false
        this.HandleHideModal()
        this.$Progress.finish()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Offer/IssueSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Offer/IssueFail') + error,
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
</style>
